/* eslint-disable react/no-unescaped-entities */
import Link from 'next/link'
import React from 'react'
import Statistic_promo_carousel from '../carousel/statistic_promo_2_carousel'

const Statistic_promo_2 = () => {
    return (
        <div>
            {/* <!-- Promo 2 --> */}
            <section className="statistic_promo_2-section bg-[#010107] py-12 lg:pb-32">
                <div className="container">
                    <h2 className="mb-6 font-display text-2xl text-white">
                        ROADMAP
                    </h2>
                    <div className="items-center justify-between lg:flex">
                        <div className="mb-12 lg:w-[45%] lg:pr-16">
                            {/* <p className="mb-8 text-lg leading-normal text-jacarta-200">
                                Phase 1: Launch NFTs on zkSync Era and build a
                                community around them.
                            </p>
                            <p className="mb-8 text-lg leading-normal text-jacarta-200">
                                Phase 2: Do our best to support and collaborate
                                promising projects on zkSync Era, explore new
                                use cases for zkBirds NFTs.
                            </p>
                            <p className="mb-8 text-lg leading-normal text-jacarta-200">
                                Phase 3: Continue to innovate and experiment for
                                NFTfi and new use case for NFT on Layer-2.
                            </p>
                            <p className="mb-12 text-jacarta-200">
                                More details will come out soon. The roadmap is
                                flexible and subject to change based on the
                                evolving needs and opportunities of the zkBirds
                                project.
                            </p> */}
                            <div className="py-10 container mx-auto px-20">
                                <div className="relative">
                                    <div
                                        className="border-r-4 border-black absolute h-full top-0"
                                        style={{ left: '9px' }}
                                    ></div>
                                    <ul className="list-none m-0 p-0">
                                        <li className="mb-5  ">
                                            <div className="flex group items-center ">
                                                <div className="ml-3 bg-gray-800 group-hover:bg-red-700 z-10 rounded-full border-8 border-black h-8 w-12">
                                                    {/* <div class="bg-black h-1 w-6 items-center  ml-4 mt-1"></div> */}
                                                </div>
                                                <div className="flex-1 ml-4 z-10 font-medium">
                                                    <div className="">
                                                        <h3 className="mb-3 font-bold text-white text-2xl">
                                                            Phase 1
                                                        </h3>
                                                        <p className="pb-4 text-lg text-gray-100">
                                                            NFTs
                                                        </p>
                                                        <hr />
                                                        <p className="mb-8 text-sm leading-normal text-jacarta-200">
                                                            Launch zkBirds NFTs
                                                            on the zkSync Era
                                                            platform and
                                                            establish a
                                                            community around
                                                            them.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-5  ">
                                            <div className="flex group items-center ">
                                                <div className="ml-3 bg-gray-800 group-hover:bg-red-700 z-10 rounded-full border-8 border-black h-8 w-12">
                                                    <div className="bg-black h-1 w-6 items-center  ml-4 mt-1"></div>
                                                </div>
                                                <div className="flex-1 ml-4 z-10 font-medium">
                                                    <div className="">
                                                        <h3 className="mb-3 font-bold text-white text-2xl">
                                                            Phase 2
                                                        </h3>
                                                        <p className="pb-4 text-lg text-gray-100">
                                                            Breeding System
                                                            (Airdrop)
                                                        </p>
                                                        <hr />
                                                        <p className="mb-8 text-sm leading-normal text-jacarta-200">
                                                            After the NFT sale
                                                            is complete, a
                                                            breeding system for
                                                            zkBirds will be
                                                            opened, allowing
                                                            users to create new
                                                            breeds of birds.
                                                            More information on
                                                            this feature will be
                                                            shared at a later
                                                            time.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-5  ">
                                            <div className="flex group items-center ">
                                                <div className="ml-3 bg-gray-800 group-hover:bg-red-700 z-10 rounded-full border-8 border-black h-8 w-12">
                                                    <div className="bg-black h-1 w-6 items-center  ml-4 mt-1"></div>
                                                </div>
                                                <div className="flex-1 ml-4 z-10 font-medium">
                                                    <div className="">
                                                        <h3 className="mb-3 font-bold text-white text-2xl">
                                                            Phase 3
                                                        </h3>
                                                        <p className="pb-4 text-lg text-gray-100">
                                                            Nesting System
                                                        </p>
                                                        <hr />
                                                        <p className="mb-8 text-sm leading-normal text-jacarta-200">
                                                            An incentivized
                                                            nest-building system
                                                            will be launched,
                                                            where users can earn
                                                            rewards by
                                                            completing different
                                                            stages of
                                                            nest-building.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-5  ">
                                            <div className="flex group items-center ">
                                                <div className="ml-3 bg-gray-800 group-hover:bg-red-700 z-10 rounded-full border-8 border-black h-8 w-12">
                                                    <div className="bg-black h-1 w-6 items-center  ml-4 mt-1"></div>
                                                </div>
                                                <div className="flex-1 ml-4 z-10 font-medium">
                                                    <div className="">
                                                        <h3 className="mb-3 font-bold text-white text-2xl">
                                                            Phase 4
                                                        </h3>
                                                        <p className="pb-4 text-lg text-gray-100">
                                                            Collaboration
                                                        </p>
                                                        <hr />
                                                        <p className="mb-8 text-sm leading-normal text-jacarta-200">
                                                            Collaborating with
                                                            renowned projects,
                                                            we are pioneering a
                                                            new metaverse for
                                                            NFT adoption. Join
                                                            us as we explore
                                                            exciting
                                                            possibilities and
                                                            innovations in the
                                                            world of NFTs.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="relative text-center lg:w-1/2">
                            <img
                                src="/images/nft-game/gradient_glow_large_2.png"
                                loading="lazy"
                                alt=""
                                className="pointer-events-none absolute scale-150"
                                style={{ top: '-90px' }}
                            />

                            <Statistic_promo_carousel />

                            {/* <img
                                src="/images/nft-game/crypto_icons_1.png"
                                alt=""
                                loading="lazy"
                                className="pointer-events-none absolute -top-10 z-10 animate-fly"
                            /> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end promo 2 --> */}
        </div>
    )
}

export default Statistic_promo_2
