import Link from 'next/link'
import Image from 'next/image'
import { useState, useEffect } from 'react'
import Modal from '../modal/mintModal'
import { getTotalSupply } from '../contract-btn/contractInteraction'

const HeroZkbirds = () => {
    const [totalSupply, setTotalSupply] = useState(6666)
    const ModalButton = () => {
        const [isOpen, setIsOpen] = useState(false)

        const handleOpenModal = () => {
            setIsOpen(true)
        }

        const handleCloseModal = () => {
            setIsOpen(false)
        }

        return (
            <>
                <button onClick={handleOpenModal}>
                    <a className="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
                        {' '}
                        Mint
                    </a>
                </button>
                <Modal isOpen={isOpen} onClose={handleCloseModal} />
            </>
        )
    }

    const sigmoid = (x) => {
        return Math.round(20000 * ((1 / (1 + Math.exp(-0.001 * x))) - 0.5));
    };
      
      
      

    useEffect(() => {
        async function _setTotalSupply() {
            const supply = await getTotalSupply()
            setTotalSupply(sigmoid(supply))
            // setTotalSupply(supply)
        }
        _setTotalSupply();
    }, [])

    return (
        <>
            {/* <!-- Hero --> */}
            <section className="hero relative md:pt-32">
                <picture className="pointer-events-none absolute inset-0 -z-10">
                    <img
                        src="/images/gradient_dark.jpg"
                        alt="gradient dark"
                        className="h-full w-full"
                    />
                </picture>
                <div className="container">
                    <div className="mx-auto max-w-2xl text-center ">
                        <div className="mb-5" height="24" width="24">
                            <Image
                                src="/images/zkbirds_logo.png"
                                alt=""
                                width={500}
                                height={170}
                            />
                        </div>
                        <p className="mx-auto mb-10 text-md leading-normal text-jacarta-50">
                            A 10k PFP collection which migrate from Moonbirds on
                            Ethereum to zkSync Era.
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'DM Sans',
                                    display: 'block',
                                    textAlign: 'center',
                                    margin: 'auto',
                                    marginBottom: '20px',
                                    fontSize: '25px',
                                    lineHeight: '3',
                                    background:
                                        'linear-gradient(to right, #FFF, #4263EB)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }}
                            >
                                {`${totalSupply}/10000`}
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <p className="mx-auto mb-10 text-md leading-normal text-jacarta-50">
                                <ModalButton />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end hero --> */}
        </>
    )
}

export default HeroZkbirds
