export const characters_data = [
	{
		title: 'Optimus Prime',
		img: '/images/nft-game/nft_game_character_1.png',
		id: 1,
		smallText1: 'Nature',
		smallText2: 'Lightning',
		icon1: 'Frame',
		icon2: 'Frame-1',
		href: 'item_33',
	},
	{
		title: 'Tractor Bowling',
		img: '/images/nft-game/nft_game_character_2.png',
		id: 2,
		smallText1: 'Ether',
		smallText2: 'Airpower',
		icon1: 'Frame-2',
		icon2: 'Frame-3',
		href: 'item_34',
	},
	{
		title: 'Rakoun',
		img: '/images/nft-game/nft_game_character_3.png',
		id: 3,
		smallText1: 'Poison',
		smallText2: 'Snow Storm',
		icon1: 'Frame-4',
		icon2: 'Frame-5',
		href: 'item_35',
	},
	{
		title: 'Skoopnum',
		img: '/images/nft-game/nft_game_character_4.png',
		id: 4,
		smallText1: 'Blaze',
		smallText2: 'Tornado',
		icon1: 'Frame-6',
		icon2: 'Frame-7',
		href: 'item_36',
	},
	{
		title: 'Bumblebee',
		img: '/images/nft-game/nft_game_character_5.png',
		id: 5,
		smallText1: 'Inferno',
		smallText2: 'Water',
		icon1: 'Frame-8',
		icon2: 'Frame-9',
		href: 'item_37',
	},
];
