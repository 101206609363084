import React from 'react';
import Characters_carousel from './carousel/characters_carousel';

const Characters = () => {
	return (
		<div>
			{/* <!-- Characters Slider --> */}
			<section className="characters-section relative pb-12">
				<picture className="pointer-events-none absolute inset-0 -z-10">
					<img src="/images/gradient_creative.jpg" alt="gradient" className="h-full w-full" />
				</picture>
				<div className="relative px-6 pb-16 sm:px-0">
					<Characters_carousel />
				</div>
			</section>
			{/* <!-- end characters slider --> */}
		</div>
	);
};

export default Characters;
