/* eslint-disable react/no-unescaped-entities */
import Link from 'next/link';
import Image from 'next/image'
import React from 'react';

const Statistic = () => {
	return (
		<div>
			{/* <!-- Statistic / Promo --> */}
			<section className="relative py-12 lg:py-36">
				<picture className="pointer-events-none absolute inset-0 -z-10 after:absolute after:bottom-0 after:h-1/3 after:w-full after:bg-gradient-to-t after:from-[#010107]">
					<img
						src="/images/gradient_creative.jpg"
						alt="gradient"
						className="h-full w-full -scale-y-100"
					/>
				</picture>
					<div className="container -mt-20 lg:pb-32">
						<div className="container pt-32">
					<div className="items-center justify-between lg:flex">
						<div className="relative text-center lg:w-1/2">
							<img
								style={{top: '-50px'}}
								src="/images/nft-game/gradient_glow_large_1.png"
								alt=""
								loading="lazy"
								classNam="relative"
								className="absolute scale-150"
							/>
							<img
								src="/images/zkbirds/pfp.jpg"
								alt=""
								loading="lazy"
								className="relative inline-block pt-10"
								width={400}
								height={400}
							/>
						</div>
						<div className="lg:w-[45%] lg:pl-16">
							<h2 className="mb-6 font-display text-2xl text-white">
								ABOUT
							</h2>
							<p className="mb-8 text-lg leading-normal text-jacarta-100">
								Community. Art. Metaverse.
							</p>
							<p className="mb-5 text-jacarta-100">
								We are an innovative NFT project built on zkSync Era to prioritize community. 
							</p>
							<p className="mb-5 text-jacarta-100">
								Passionate about web3 and Ethereum's future, we will do our best to become a DAO and foster a diverse, inclusive community. 
							</p>
							<p className="mb-2 text-jacarta-100">
								By implementing advanced features, smart contracts, and NFTfi solutions, we will revolutionize user experience, and promote innovation within the NFT ecosystem on zkEVM chains.
							</p>
						</div>
					</div>
				</div>
					</div>

				{/* <!-- Promo 1 --> */}
				
			</section>
			{/* <!-- end statistic / promo --> */}
		</div>
	);
};

export default Statistic;
